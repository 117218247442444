import _ from 'lodash';

const computedNumberFormatter = (field = '', options = {}) => ({
    get() {
        return new Intl.NumberFormat('ru-RU', options)
            .format(
                _.get(this, field)
            )
    },
    set(number) {
        this.$set(this, field, parseFloat(number.replace(/\s/g, '')))
    }
})

export {
    computedNumberFormatter
}
