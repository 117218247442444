<template>
    <v-card elevation="0" class="mr-4 mr-md-0 bg-bg" height="100%" color="white">
        <router-link
            :to="{name: 'projects.show', params: {project: popular_new_buildings.slug}}"
            class="text-decoration-none d-flex flex-column link_popularNewBuildings"
            style="height: 100%"
        >
            <v-img :src="popular_new_buildings.image_url"
                   height="163px"
                   max-height="163px"></v-img>
            <v-card-title class="font-weight-medium fz-24 black-color-text">
                {{ popular_new_buildings.name }}
            </v-card-title>
            <v-card-title v-if="minPrice" class="font-weight-light subtitle-1 mt-auto price black-color-text">
                {{ $t('headers.from') }} {{ displayMinPrice }}
            </v-card-title>
<!--            <v-card-title v-else class="font-weight-light subtitle-1 mt-auto price black-color-text">-->
<!--                {{ `    `}}-->
<!--            </v-card-title>-->
        </router-link>
    </v-card>
</template>

<script>
import {computedNumberFormatter} from "@/utils/number.formatter";
export default {
    name: "PopularNewBuilding",
    props: ['popular_new_buildings'],
    data: function () {
        return {}
    },
    computed: {
        displayMinPrice: computedNumberFormatter('minPrice'),
        minPrice() {
            let cheapestApartment = this.$_.minBy(this.popular_new_buildings.apartments, 'price')
            return cheapestApartment ? cheapestApartment.price : ''
        },
    }
}
</script>

<style lang="scss" scoped>

.v-card__title {
    word-break: normal;
}

.card {
    height: 300px;
}

.fz-24 {
    font-size: 24px !important;
    color: #000000;
}


.v-application .mt-auto.price {
    @media all and (max-width: 900px) {
        margin-top: auto;
        color: black;
    }
}

.carousel-circle .v-carousel--hide-delimiter-background .v-carousel__controls {
    @media all and (max-width: 900px) {
        background: whitesmoke !important;
    }
}

.black-color-text {
    color: black;
}

.link_popularNewBuildings {
    @media all and (max-width: 900px) {
        max-height: 310px;
        background: white;
    }
}

.v-application .white {
    background-color: #FFFFFF !important;
    border-color: #FFFFFF !important;

    @media all and (max-width: 900px) {
        background-color: var(--bg) !important;
        border-color: var(--bg) !important;
    }
}

.bg-bg {
    background: var(--bg) !important;
}

.v-card {
    min-height: 307.5px;
}
</style>
